(function(){

	var botaoTopo = $('[data-gototop="true"]');

	var alvosAnimacao = $('body,html');

	var topo = $('.topo');

	var alturaTopo = topo.innerHeight() - 27;

	$(window).scroll(function(){

		if($(this).scrollTop() > alturaTopo){
			botaoTopo.addClass('fixo');
		}else{
			botaoTopo.removeClass('fixo');
		}

	});



})();